var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('v-row',{staticClass:"ak-memory-content",attrs:{"no-gutters":""}},_vm._l((_vm.answerList),function(item,index){return _c('v-col',{key:index,staticClass:"ak-memory-tile",class:[
      { 'blocked-all': _vm.memoryTimeIsOut || _vm.timeIsOut },
      { isHidden: !_vm.isSelected(item) && !_vm.isReopenedQuestion },
      { 'tile-selected': _vm.isSelected(item) && _vm.activeIndex.length >= 1 },
      { 'tile-correct': _vm.isSelected(item) && _vm.isCorrect(item) },

      _vm.activeIndex.length == 2 && _vm.isChecked
        ? _vm.isCorrect(item)
          ? 'tile-correct'
          : 'tile-incorrect'
        : '',

      {
        'tile-incorrect':
          _vm.isReopenedQuestion && !_vm.isSelected(item) && !_vm.isCorrect(item),
      } ],attrs:{"cols":"auto"},on:{"click":function($event){return _vm.selectAnswer(item)}}},[_c('p',{staticClass:"mb-0 ak-question-answer"},[_vm._v(" "+_vm._s(item.name)+" ")]),(_vm.isReopenedQuestion)?[_c('span',{staticClass:"ak-answer-number"},[_vm._v(_vm._s(item.number + 1))])]:_vm._e()],2)}),1)}
var staticRenderFns = []

export { render, staticRenderFns }