<template>
  <v-row
    no-gutters
    justify="center"
    align="center"
    class="mb-5 mb-md-10"
    :class="[
      Boolean(subtitle) ? 'mb-9' : 'mb-10 pb-5',
      $vuetify.breakpoint.smAndDown ? 'mt-5' : '',
    ]"
  >
    <AKButton
      @click="goBack()"
      icon="ArrowLeft"
      :height="$vuetify.breakpoint.mdAndDown ? '34' : '38'"
      :width="$vuetify.breakpoint.mdAndDown ? '40' : '51'"
      class="goBackBtn"
    />
    <v-col cols="auto">
      <template v-if="Boolean(title)">
        <h1 class="ak-page-title">{{ title }}</h1>
      </template>
      <template v-if="Boolean(subtitle)">
        <p class="ak-page-subtitle">{{ subtitle }}</p>
      </template>
    </v-col>
  </v-row>
</template>

<script>
import AKButton from '@/components/common/core/AKButton';

export default {
  name: 'PageTitle',
  components: { AKButton },
  props: {
    title: {
      type: String,
      default: null,
    },
    subtitle: {
      type: String,
      default: null,
    },
  },
  methods: {
    goBack() {
      if (window.history.length <= 1) {
        this.$router.push({ name: 'Home' });
      } else {
        this.$router.go(-1);
      }
    },
  },
};
</script>

<style lang="scss" scoped>
.ak-page-title,
.ak-page-subtitle {
  font-family: 'Roboto';
  font-weight: bold;
  font-size: 20px;
  line-height: 23px;
  color: var(--v-secondary-base);
  text-align: center;
  margin-bottom: 5px;

  @media (min-width: map-get($grid-breakpoints, md)) {
    font-size: 24px;
    line-height: 27px;
  }
}

.ak-page-subtitle {
  font-size: 16px;
  line-height: 18.75px;
  margin-bottom: 0;

  @media (min-width: map-get($grid-breakpoints, md)) {
    font-size: 20px;
    line-height: 22px;
  }
}
.goBackBtn {
  position: absolute;
  left: 14px;
  margin: auto;
}
</style>
