<template>
  <v-row no-gutters class="ak-memory-content">
    <v-col
      v-for="(item, index) in answerList"
      :key="index"
      cols="auto"
      class="ak-memory-tile"
      :class="[
        { 'blocked-all': memoryTimeIsOut || timeIsOut },
        { isHidden: !isSelected(item) && !isReopenedQuestion },
        { 'tile-selected': isSelected(item) && activeIndex.length >= 1 },
        { 'tile-correct': isSelected(item) && isCorrect(item) },

        activeIndex.length == 2 && isChecked
          ? isCorrect(item)
            ? 'tile-correct'
            : 'tile-incorrect'
          : '',

        {
          'tile-incorrect':
            isReopenedQuestion && !isSelected(item) && !isCorrect(item),
        },
      ]"
      @click="selectAnswer(item)"
    >
      <p class="mb-0 ak-question-answer">
        {{ item.name }}
      </p>
      <template v-if="isReopenedQuestion">
        <span class="ak-answer-number">{{ item.number + 1 }}</span>
      </template>
    </v-col>
  </v-row>
</template>

<script>
export default {
  name: 'MemoryQuestion',
  props: {
    questionAnswersTaked: {
      type: Array,
      default: () => [],
    },
    questionAnswers: {
      type: Array,
      default: () => [],
    },
    questionPostLink: {
      type: String,
      default: null,
    },
    isReopenedQuestion: {
      type: Boolean,
      default: false,
    },
    passedQuestions: {
      type: Number,
      default: null,
    },
    modalType: {
      type: String,
      default: null,
    },
    memoryTimeIsOut: {
      type: Boolean,
      default: false,
    },
    timeIsOut: {
      type: Boolean,
      default: false,
    },
  },

  data() {
    return {
      correctCount: 0,
      activeIndex: [],
      selectedAnswers: [],
      isChecked: false,
      gameAnswers: [],
      timeout: null,
    };
  },

  watch: {
    memoryTimeIsOut(val) {
      if (val) {
        this.$emit('memoryTimeOut', true);
      }
    },

    passedQuestions: {
      immediate: true,
      handler(oldVal, newVal) {
        if (oldVal < newVal) {
          this.$emit('validGoNextButton', oldVal < newVal);
        } else {
          this.$emit('validGoNextButton', false);
        }
      },
    },

    correctCount(val) {
      if (val * 2 === this.questionAnswers.length) {
        this.$emit('validGoNextButton', true);
      }
    },
  },

  created() {
    this.getAnswers();
    this.updateAnswers();
  },

  computed: {
    answerList() {
      return !this.isReopenedQuestion && this.questionAnswers.length > 0
        ? this.questionAnswers
        : this.gameAnswers;
    },
  },

  methods: {
    updateAnswers() {
      this.questionAnswersTaked.forEach((item) => {
        this.activeIndex.push(item.choiceA);
        this.activeIndex.push(item.choiceB);

        this.activeIndex.forEach((item) => {
          this.questionAnswers.forEach((el) => {
            if (el.id === item) {
              el.isCorrect = true;
              el.isSelected = true;
            }
          });
        });

        this.correctCount += 1;
      });
    },

    isCorrect(item) {
      if (this.isReopenedQuestion) {
        if ('correct' in item) {
          return item.correct;
        } else {
          return false;
        }
      } else {
        return Boolean(item.isCorrect);
      }
    },

    isSelected(item) {
      if (this.isReopenedQuestion) {
        if ('given' in item) {
          return item.given;
        } else {
          return false;
        }
      } else {
        return (
          (item.isSelected && item.isCorrect) ||
          Boolean(this.activeIndex.includes(item.id))
        );
      }
    },

    async validQuestion() {
      if (this.activeIndex.length === 2) {
        const answer = {
          choiceA: this.activeIndex[0],
          choiceB: this.activeIndex[1],
          type: 'PAIRED',
        };

        await this.$axios
          .post(this.questionPostLink, answer, this.axiosPostPutConfig)
          .then((res) => {
            if (this.modalType === 'challenge') {
              this.$emit('startMemoryTimer');
            }

            this.isChecked = true;
            this.activeIndex.forEach((item) => {
              this.questionAnswers.forEach((el) => {
                if (el.id === item) {
                  el.isCorrect = res.data.data.correct;
                  el.isSelected = true;
                }
              });
            });
            if (res.data.data.correct) {
              this.correctCount += 1;
            } else {
              this.activeIndex.forEach((item) => {
                this.questionAnswers.forEach((el) => {
                  if (el.id === item) {
                    delete el.isCorrect;
                    delete el.isSelected;
                  }
                });
              });
            }
          });

        this.timeout = setTimeout(() => {
          this.isChecked = false;
          this.activeIndex = [];
        }, 1000);
      }
    },

    selectAnswer(item) {
      if (this.isReopenedQuestion) {
        return true;
      } else {
        if (!this.isChecked) {
          let tmpIndex = item.id;

          if (
            this.activeIndex.length <= 1 &&
            !this.activeIndex.includes(tmpIndex) &&
            !item.isSelected
          ) {
            this.activeIndex.push(tmpIndex);
            this.activeIndex = Array.from(new Set(this.activeIndex));
          } else {
            this.activeIndex = this.activeIndex.filter(
              (item) => item !== tmpIndex
            );
          }
          if (this.activeIndex.length >= 1) {
            this.validQuestion();
          }
        } else {
          this.isChecked = false;
          this.activeIndex = [];
          this.activeIndex.push(item.id);
          this.activeIndex = Array.from(new Set(this.activeIndex));
          clearTimeout(this.timeout);
        }
      }
    },

    shuffleArray(obj) {
      var result = Object.keys(obj).map((key) => [Number(key), obj[key]]);

      let shuffled = result
        .map((value) => ({ value, sort: Math.random() }))
        .sort((a, b) => a.sort - b.sort)
        .map(({ value }) => value);

      return shuffled;
    },

    clearArray(array) {
      let arr = array.map((value) => value[1][1]);

      return arr;
    },

    getAnswers() {
      if (this.isReopenedQuestion) {
        let tmp = [];
        this.questionAnswers.forEach((el, index) => {
          tmp.push({
            name: el.b.name,
            id: el.b.id,
            correct: el.correct,
            given: el.given,
            number: index,
          });
        });

        this.questionAnswers.forEach((el, index) => {
          tmp.push({
            name: el.a.name,
            id: el.a.id,
            correct: el.correct,
            given: el.given,
            number: index,
          });
        });

        let tmpArray = this.shuffleArray(tmp);
        let tmpArray2 = this.shuffleArray(tmpArray);
        this.gameAnswers = this.clearArray(tmpArray2);
      } else {
        return false;
      }
    },
  },
};
</script>

<style lang="scss" scoped>
.ak-question {
  background-color: var(--v-primary-base);
  position: relative;
}

.ak-memory {
  &-tile {
    height: 100px;
    width: 100px;
    margin: 0 10px 10px 0;
    background-color: var(--v-secondary-base);
    border-radius: 5px;
    font-family: 'Roboto';
    font-weight: bold;
    font-size: 16px;
    line-height: 19px;
    color: var(--v-text-base);
    display: flex;
    justify-content: center;
    align-items: center;
    cursor: pointer;
    position: relative;

    &:nth-child(3n + 3) {
      margin-right: 0;
    }

    &.isHidden {
      position: relative;
      border-radius: 5px;
      overflow: hidden;

      &:before {
        content: '';
        position: absolute;
        height: 100%;
        width: 100%;
        background-color: var(--v-secondary-base);
      }
    }

    &.tile {
      &-correct {
        background-color: var(--v-success-base) !important;
        color: #fff;
      }

      &-incorrect {
        background-color: var(--v-error-base) !important;
        color: #fff;
      }

      &-selected {
        background-color: var(--v-accent-base);
      }
    }
  }

  &-content {
    display: flex;
    justify-content: center;
    max-width: 350px;
    margin: auto;
  }
}

.ak-question-answer {
  width: 100%;
  padding: 3px;
  overflow: scroll;
  height: 100%;
  display: flex;
  word-break: break-word;
  align-items: center;
  align-content: center;
  text-align: center;
  line-height: 1.2;
  justify-content: center;
  -ms-overflow-style: none; /* Internet Explorer 10+ */
  scrollbar-width: none; /* Firefox */

  &::-webkit-scrollbar {
    display: none; /* Safari and Chrome */
  }
}

.blocked-all {
  pointer-events: none;
}

.ak-answer-number {
  position: absolute;
  top: 5px;
  right: 5px;
  background: var(--v-secondary-base);
  color: var(--v-fontGrey-base);
  border-radius: 50%;
  height: 20px;
  width: 20px;
  display: flex;
  justify-content: center;
  font-size: 12px;
  align-items: center;
}
</style>
