<template>
  <v-container class="ak-page-container pb-10">
    <PageTitle title="Einstellungen" />
    <v-row class="ma-0 ak-general-info">
      <v-col cols="6">
        <p class="ak-general-info-title">Nickname</p>
        <v-text-field
          width="auto"
          dense
          placeholder="Nickname"
          class="font16-600 ak-username"
          v-model="user.name"
          hide-details
        />
      </v-col>
      <v-col cols="12" class="d-flex justify-start align-center">
        <AKAvatar :hasUserInfo="hasUserInfo" :avatar="user.photo" />
        <a target="_blank" href="https://www.gravatar.com/">
          <p class="ml-5 ak-general-info-title">Avatar ändern</p>
        </a>
      </v-col>
      <v-col cols="12">
        <p class="ak-general-info-title">E-Mail</p>
        <p class="font16-600 ak-email">
          {{ user.email }}
        </p>
      </v-col>
      <v-col cols="12">
        <p class="ak-general-info-title">Kontakt/Hilfe</p>
        <a
          href="mailto:anaking.gesundheit@zhaw.ch"
          class="font16-600 ak-support-email"
        >
          anaking.gesundheit@zhaw.ch
        </a>
      </v-col>
    </v-row>
    <v-row class="ak-additional">
      <v-col
        cols="12"
        class="d-flex flex-column flex-lg-row justify-space-between"
      >
        <AKButton
          class="mb-4 mb-lg-0"
          @click="save()"
          :height="$vuetify.breakpoint.mdAndDown ? '48' : '53'"
          :width="$vuetify.breakpoint.mdAndDown ? '100%' : '200'"
          title="Speichern"
        />

        <AKButton
          :hrefLink="'/oauth/logout'"
          :height="$vuetify.breakpoint.mdAndDown ? '48' : '53'"
          :width="$vuetify.breakpoint.mdAndDown ? '100%' : '200'"
          :color="$vuetify.theme.themes.light.brightBlue"
          title="Logout"
        />
      </v-col>
    </v-row>
  </v-container>
</template>

<script>
import AKButton from '@/components/common/core/AKButton';
import AKAvatar from '@/components/common/core/AKAvatar';
import PageTitle from '@/components/common/PageTitle';
import CommonMixins from '@/mixins/CommonMixins';

export default {
  name: 'Settings',
  components: { PageTitle, AKButton, AKAvatar },
  mixins: [CommonMixins],

  data() {
    return {
      user: {
        name: null,
        photo: null,
        email: null,
      },
    };
  },

  created() {
    this.setUserInfo();
  },

  mounted() {
    this.setUserInfo();
  },

  methods: {
    setUserInfo() {
      if (this.hasUserInfo) {
        this.user.name = this.userInfo.nickname || this.userInfo.name;
        this.user.photo = this.userInfo.avatar;
        this.user.email = this.userInfo.email;
        this.$forceUpdate();
      }
    },
    updateUserInfo() {
      this.$store.dispatch('user/loadUserData');
    },
    async save() {
      const user = [
        {
          nickname: this.user.name,
        },
      ];

      await this.$axios
        .put('/api/v1/user', user, this.axiosPostPutConfig)
        .then((res) => {
          console.log('RESPONSE RECEIVED put user: ', res);
          this.updateUserInfo();
        })
        .catch((err) => {
          console.log('AXIOS ERROR put user: ', err);
        });
    },
  },
};
</script>

<style lang="scss" scoped>
.ak-page-container {
  @media (min-width: map-get($grid-breakpoints, sm)) {
    height: 100vh;
  }

  @media (min-width: map-get($grid-breakpoints, md)) {
    height: auto;
  }
}
.ak-general-info {
  background: var(--v-secondary-base);
  padding: 35px 20px;
  border-radius: 10px;

  &-title {
    font-family: 'Roboto';
    font-weight: 500;
    font-size: 16px;
    line-height: 19px;
    color: #000000 !important;
    margin-bottom: 4px;
  }

  a {
    text-decoration: none;
    color: inherit;
  }
}

.ak-additional {
  @media (min-width: map-get($grid-breakpoints, sm)) {
    position: absolute;
    width: 100%;
    bottom: 20px;
  }

  @media (min-width: map-get($grid-breakpoints, md)) {
    position: static;
    width: auto;
  }
}
</style>
